import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultTransactionData = {
  id: 0,
  userId: undefined,
  orderId: undefined,
  orderReference: undefined,
  transactionReference: undefined,
  transactionDate: undefined,
  status: undefined,
  productinfo: '',
  amount: 0,
  paymentGateway: '',
  paymentMethod: undefined,
  bank: undefined,
  utrNumber: null,
  errorCode: '',
  errorMessage: '',
  user: {
    id: undefined
  }
};
export var getTransactions = function getTransactions(params) {
  return request({
    url: '/transactions',
    method: 'get',
    params: params
  });
};
export var createTransaction = function createTransaction(data) {
  return request({
    url: '/transactions',
    method: 'post',
    data: data
  });
};
export var getTransactionById = function getTransactionById(id) {
  return request({
    url: "/transactions/".concat(id),
    method: 'get'
  });
};
export var updateTransaction = function updateTransaction(id, data) {
  return request({
    url: "/transactions/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteTransaction = function deleteTransaction(id) {
  return request({
    url: "/transactions/".concat(id),
    method: 'delete'
  });
};