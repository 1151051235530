var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.orderInfoData
        ? _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Info" } },
                [_c("order-info-tab", { attrs: { data: _vm.orderInfoData } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Products" } },
                [
                  _c("order-product-tab", {
                    attrs: { data: _vm.orderInfoData }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Users" } },
                [_c("order-user-tab", { attrs: { data: _vm.orderInfoData } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Transactions" } },
                [
                  _c("order-transaction-tab", {
                    attrs: { "order-id": _vm.orderId }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Dispatches" } },
                [
                  _c("order-dispatch-tab", {
                    attrs: { "order-id": _vm.orderId }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }