var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.data && _vm.data.ordersProduct
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.data.ordersProduct }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "product.name", label: "Name", width: "280" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(" " + _vm._s(scope.row.product.name) + " "),
                          _c("br"),
                          _c("small", [
                            _vm._v(
                              "(" +
                                _vm._s(scope.row.productsVariation.name) +
                                ")"
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  120073726
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "unitPrice", label: "Unit Price", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", { attrs: { number: scope.row.unitPrice } })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4177193140
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "salePrice", label: "Sale Price", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", { attrs: { number: scope.row.salePrice } })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3818098153
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "quantity", label: "Qty", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "taxedAmount", label: "Tax", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", {
                            attrs: { number: scope.row.taxedAmount }
                          }),
                          _vm._v(" (" + _vm._s(scope.row.taxRate) + " %) ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1405469693
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "discountAmount",
                  label: "Discount",
                  width: "120"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", {
                            attrs: { number: scope.row.discountAmount }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3882762990
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "subTotal", label: "Total", width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", { attrs: { number: scope.row.subTotal } })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2761740793
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }