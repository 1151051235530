var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.list
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  sortable: "custom",
                  width: "80",
                  align: "center",
                  label: _vm.$t("table.id"),
                  prop: "id"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "transactionReference",
                  label: "Reference"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.transactionReference ||
                                  scope.row.utrNumber
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3768687330
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "status",
                  label: "Status"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-tag",
                            { attrs: { type: "success", size: "mini" } },
                            [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2082611567
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "amount",
                  label: "Amount"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("inr", { attrs: { number: scope.row.amount } })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1611051571
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "paymentGateway",
                  label: "Method"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.paymentGateway
                            ? _c("el-tag", { attrs: { size: "mini" } }, [
                                _vm._v(
                                  " " + _vm._s(scope.row.paymentGateway) + " "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2132845993
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "paymentMethod",
                  label: "Mode"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Transaction Date",
                  width: "150px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.transactionDate,
                                  "DD-MM-YYYY"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  831365960
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.createdDate"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.createdTimestamp,
                                  "DD-MM-YYYY hh:mm a"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1390049389
                )
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "Actions", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.status === "pending"
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/transaction/rtgs/" + scope.row.id
                                  }
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-edit"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1103930509
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }