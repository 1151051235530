var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.list
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  sortable: "custom",
                  width: "80",
                  align: "center",
                  label: _vm.$t("table.id"),
                  prop: "id"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "quantity",
                  label: "Qty"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  align: "center",
                  prop: "comments",
                  label: "Comments"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "Attachments", width: "200px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            [
                              _vm._v(" TransportBilty: "),
                              scope.row.transportBilty
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: scope.row.transportBilty,
                                        type: "primary",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" view ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm._v(" Invoice Attachment: "),
                              scope.row.invoiceAttachment
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: scope.row.invoiceAttachment,
                                        type: "primary",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" view ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm._v(" Other Attachment: "),
                              scope.row.otherAttachment
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: scope.row.otherAttachment,
                                        type: "primary",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(" view ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2405733319
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Dispatch Date",
                  width: "150px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.dispatchDate,
                                  "DD-MM-YYYY"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1516347420
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.createdDate"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.createdTimestamp,
                                  "DD-MM-YYYY hh:mm a"
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1390049389
                )
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "Actions", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Previous Dispatch",
                                placement: "top"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-tickets",
                                  size: "mini"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleDialog(scope.row.id)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2864620320
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Order Dispatch Details",
            width: "90%",
            visible: _vm.dialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm._v(" Products dispatched "),
          _c("dispatch-list", {
            key: _vm.dispatchId,
            attrs: { "orders-dispatch-id": _vm.dispatchId }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }